<template>
  <div>
    <vx-card class="mb-4">
      <h4 class="mb-4">
        {{ $t(resources.Filters.i18n) || resources.Filters.name }}
      </h4>
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/4 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.LandingPage.i18n) || resources.LandingPage.name
            }}</label>
            <v-select
              v-model="landing"
              :clearable="true"
              :options="landingOptions"
              :reduce="item => item.id"
              label="name"
            />
          </div>
        </div>

        <div class="vx-col mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="get()">{{
            $t(resources.Search.i18n) || resources.Search.name
          }}</vs-button>
        </div>
      </div>
    </vx-card>
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    landingList.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : landingList.length
                  }}
                  of {{ landingList.length }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
              CVS
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="landingList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import database from "@/assets/resources/enums/database.json";

export default {
  components: {
    AgGridVue,
    Datepicker,
    vSelect
  },
  data() {
    return {
      database: database,
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      languages: lang,
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      landing: null,
      landingOptions: [],

      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      NumberPage: 0,
      landingList: [],
      defaultColDef: {
        sortable: true,
        editable: true,
        resizable: false,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: "#",
          field: "id",
          filter: true,
          width: 105
        },
        {
          headerName: this.$i18n.t("LandingPage"),
          field: "page.name",
          filter: true
        },
        {
          headerName: this.$i18n.t("Name"),
          field: "name",
          filter: true
        },
        {
          headerName: this.$i18n.t("LastName"),
          field: "lastname",
          filter: true
        },
        {
          headerName: this.$i18n.t("Email"),
          field: "email",
          filter: true,
         headerClass: 'resizable-header'
        },
        {
          headerName: this.$i18n.t("Phone"),
          filter: true,
          field: "phone",
          cellRenderer: row => {
            return `+${row.node.data.countryIndicative} ${row.node.data.phone}`;
          }
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  created: function() {
    this.getLandingType();
    this.get();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async getLandingType() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_LANDING}pages/get`,
        headers: {
          "content-type": "application/json",
          "accept-language": `${this.$i18n.locale}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.landingOptions = [];
          } else {
            this.landingOptions = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async get() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_LANDING}contact/get`,
        data: {
          pageId: this.landing,
          iboCode: this.itaCode
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.status === 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.landingList = [];
          } else {
            this.landingList = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>

<style>
.includeIconOnly.large {
  width: 60px !important;
  height: 60px !important;
  font-size: 0.7em;
}

.vs-button.large {
  font-size: 3.25rem;
}

.vs-button.large .vs-button--icon {
  font-size: 3.25rem;
}
</style>
